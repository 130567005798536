/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import moment from 'moment';
import menu from './menu';
import { styled } from '@mui/material';
import toggle from './../../../../../_metronic/_assets/js/layout/base/aside.js';
import { fetchBookingList } from '../../../../../app/modules/ECommerce/_redux/booking/actions';
import { getBookingSetting } from '../../../../../app/modules/ECommerce/_redux/booking/crud';
import { fadeColor } from '../../../../../app/pages/shop-setting/suggest-color';
import { fetchBookingBranchList } from '../../../../../app/modules/ECommerce/_redux/booking2/booking2Actions';
import { getAllWaittingBookingCount } from '../../../../../app/modules/ECommerce/pages/bookingV2/bookingBranch/getAllWaittingBookingCount';
import { fetchBookingShopSetting } from '../../../../../app/modules/ECommerce/_redux/booking2/booking2CRUD.js';
import {
  useIsCouponActive,
  useIsPackageActive,
  useIsPromotionActive,
  useIsSalesQrActive,
  useIsShoppingCartActive
} from '../../../../../app/hooks/useFeatureFlag';
import { usePendingOrders } from '../../../../../app/hooks/usePendingOrders.js';
import { useCustomTheme } from '../../../../../app/hooks/useCustomTheme.js';
import { useAppAliasIntlFormatter } from '../../../../../app/hooks/formatFeatureName.js';
import { initCustomrole } from '../../../../../app/modules/ECommerce/pages/adminManage/roleManage/constant.js';

export function AsideMenuList() {
  const { user, shop, shopSetting } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const formatMessage = useAppAliasIntlFormatter();

  const [bookingSetting, setBookingSetting] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const isBookingShop = shop?.isBooking || false;
  const isBooking2Shop = shop?.isBooking2 || false;
  const isGrantonShop = shop?.isGranton || false;
  const isShortcutShop = shop?.isShortcut || false;
  const couponEnabled = useIsCouponActive();
  const packageEnabled = useIsPackageActive();
  const promotionEnabled = useIsPromotionActive();
  const isContactActive = shop?.contactEnabled || false;
  const isSalesQrActive = useIsSalesQrActive();
  const shoppingCartEnabled = useIsShoppingCartActive();
  const isGrantonActiveShop = shop?.activeShopGranton || false;
  const branchEnabled = shop?.maxNumberOfBranches > 0;
  const enableSmartPos = shop?.enableSmartPos || false;
  const isGasShop = shop?.enableGas || false;
  const walletEnabled = shop?.enableWallet || false;
  const isUploadFileShop = shop?.enableUploadFile || false;

  const isGlobalAdmin = !user?.assignedBranches?.length;

  const isCustomer = user?.type === 'customer';
  const bookingData = useSelector(state => state.booking.items);
  const { data: waitConfirmOrderAmount } = usePendingOrders();

  const initialMenuListToggleStatus =
    user && menu(user?.seqId)[user?.type].reduce((acc, elem) => ({ ...acc, [elem.id]: false, 'menu.home': true }), {});
  const [menuListStatus, setMenuListStatus] = useState(initialMenuListToggleStatus || {});
  const waitConfirmBookingAmount = bookingData.filter(item => item.status === 'wc' && moment(item.date) > moment())
    .length;

  const getMenuItemActive = url => {
    return location.pathname === url ? ' menu-item-active menu-item-open ' : '';
  };

  const { branchList } = useSelector(state => state.booking2);

  const totalWaitingBooking2Count = getAllWaittingBookingCount(branchList);
  const [booking2ShopSetting, setBooking2ShopSetting] = useState(null);

  const fetchBooking2ShopSetting = async () => {
    try {
      if (shop?.isBooking2) {
        const { data } = await fetchBookingShopSetting(shop?._id);
        if (data) setBooking2ShopSetting(data);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    fetchBooking2ShopSetting();
  }, [shop, isCustomer]);

  useEffect(() => {
    // console.log('user', user);
    if (!user) return;
    dispatch(fetchBookingList(isCustomer, ''));
  }, [user, isCustomer]);

  useEffect(() => {
    if (user?.seqId == null) return;
    getBookingSetting(user.seqId).then(res => setBookingSetting(res.data.queryResult || null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(fetchBookingBranchList());
  }, [user?.seqId]);

  const customTheme = useCustomTheme();
  const style = customTheme?.sidebar;

  const handelMounseEnter = (e, type, focus) => {
    const isFocus = focus && location.pathname === focus?.route;
    if (isFocus) {
      return;
    }
    if (type === 'menu') {
      e.currentTarget.style.background = style?.hoverBgColor;
    }
    if (type === 'submenu') {
      e.currentTarget.style.background = style?.subHoverBgColor;
    }
    if (type === 'font') {
      e.currentTarget.style.color = style?.hoverColor;
    }
    if (type === 'subfont') {
      e.currentTarget.style.color = style?.subHoverColor;
    }
  };

  const handelMounseLeave = (e, type, focus) => {
    const isFocus = focus && location.pathname === focus?.route;
    if (isFocus) {
      return;
    }
    if (type === 'menu') {
      e.currentTarget.style.background = style?.bgColor;
    }
    if (type === 'submenu') {
      e.currentTarget.style.background = style?.subBgColor;
    }
    if (type === 'font') {
      e.currentTarget.style.color = style?.color;
    }
    if (type === 'subfont') {
      e.currentTarget.style.color = style?.subColor;
    }
  };

  const menuBackground = menu => {
    if (location.pathname === menu.route && !menu.isSubMenu) return style.focusBgColor;
    if (location.pathname === menu.route && menu.isSubMenu) return style.subFocusBgColor;
    if (menu.isSubMenu) return style.subBgColor;
    return style.bgColor;
  };

  const [hiddenMenuItems, setHiddenMenuItems] = useState([]);
  const [hiddenSubMenuItems, setHiddenSubMenuItems] = useState([]);

  const filterMenu = item => {
    if (user.type === 'shop-custom' && user?.customRole && hiddenMenuItems.includes(item.id)) return false;

    const isBooking1Menu = ['menu.booking', 'menu.booking.management'].includes(item.id);
    if (!bookingSetting?.isActive && isCustomer && isBooking1Menu) {
      return null;
    }

    const isBooking2Menu = ['menu.booking2', 'menu.booking2.management'].includes(item.id);
    if (!isBookingShop && isBooking1Menu) {
      return false;
    }
    if (!isBooking2Shop && isBooking2Menu) {
      return false;
    }

    if (
      (booking2ShopSetting == null || booking2ShopSetting?.isActiveCustomerBooking2Menu === false) &&
      isBooking2Menu &&
      isCustomer
    ) {
      return null;
    }
    //

    const unallowedShopGrantop = ['menu.granton.list', 'menu.granton.action', 'menu.granton.history'];
    if (!isGrantonShop && item.id.includes('menu.granton')) return false;
    if (!isGrantonActiveShop && unallowedShopGrantop.includes(item.id)) return false;

    const unallowedShoppingCart = ['menu.order.history', 'menu.shop.management'];
    if (!shoppingCartEnabled && unallowedShoppingCart.includes(item.id)) return false;
    if (!couponEnabled && item.id === 'MENU_CREATE_COUPONS') return false;
    if (!packageEnabled && item.id === 'SHOP.PACKAGE.CREATE') return false;
    if (!promotionEnabled && item.id === 'MENU_CREATE_PROMOTION') return false;
    if (!isContactActive && item.id.includes('contact.management')) return false;
    if (!isSalesQrActive && item.id === 'menu.sales.management') return false;
    if (!branchEnabled && item.id === 'MENU_BRANCH_MANAGEMENT') return false;

    if (!enableSmartPos && item.id === 'menu.pos') return false;
    if (!isUploadFileShop && item.id === 'menu.upload.file') return false;

    if (
      user.type === 'shop-custom' &&
      Object.values(user?.reportPermission || {}).every(value => value === false) &&
      ['MENU_REPORT'].includes(item.id)
    )
      return false;
    if (user.loggedInBranch && ['MENU_REPORT', 'MENU_SETTING', 'MENU_BRANCH_MANAGEMENT'].includes(item.id))
      return false;

    return true;
  };

  const filterSubMenu = sub => {
    if (!shop) return false;
    if (user?.type === 'shop-custom' && user?.customRole && hiddenSubMenuItems.includes(sub.id)) return false;
    if (!shop.isBooking && sub.id === 'menu.booking.history') return false;
    if (!shop.enableShoppingCart && (sub.id === 'menu.order.history' || sub.id === 'menu.order.history.new'))
      return false;

    if (!shop.enableWhatsapp && ['MENU_FILTER_MANAGEMENT', 'menu.broadcast.manage'].includes(sub.id)) return false;

    if (
      (!shop?.enableLineNotification ||
        (!shop?.enableShoppingCart && !shop?.isBooking && !shop?.isBooking2) ||
        !isGlobalAdmin) &&
      sub.id.includes('menu.lineOA.setting')
    )
      return false;

    if (!shop?.isBooking2 && sub.id === 'menu.booking2.history') return false;

    if ((!shop?.enableFunsharing || !isGlobalAdmin) && sub.id === 'MENU_FUNSHARING') return false;

    if (
      (!shop?.enableMGM || !isGlobalAdmin) &&
      ['MENU_MEMBER_GET_MEMBER_MANAGEMENT', 'MENU_MEMBER_GET_MEMBER_HISTORY', 'MENU_MEMBER_GET_MEMBER_REPORT'].includes(
        sub.id
      )
    )
      return false;

    if (
      !isGasShop &&
      [
        'menu.inventory.request.management',
        'menu.order.gas.create',
        'menu.inventory.history',
        'menu.addOn.management',
        'menu.order.history.gas'
      ].includes(sub.id)
    ) {
      return false;
    }
    if (!isShortcutShop && sub.id === 'menu.shortcut.setting') return false;
    //TODO: hide payment page
    if (
      !isGlobalAdmin &&
      ['menu.category', 'menu.shipment.management', 'menu.payment.management', 'menu.shopping.cart.setting'].includes(
        sub.id
      )
    )
      return false;

    if (!isGlobalAdmin && ['menu.dashboard.branch'].includes(sub.id)) return false;

    if (!walletEnabled && sub.id === 'menu.dashboard.wallet') return false;
    if (!packageEnabled && sub.id === 'menu.dashboard.package') return false;
    if (!promotionEnabled && sub.id === 'menu.dashboard.promotion') return false;
    if (!couponEnabled && sub.id === 'menu.dashboard.coupon') return false;

    return true;
  };

  useEffect(() => {
    if (user?.customRole && shop) {
      const { menu, submenu } = getRoleMenu(user?.customRole);
      setHiddenMenuItems(prev => [...prev, ...menu]);
      setHiddenSubMenuItems(prev => [...prev, ...submenu]);
    }
  }, [user, shop]);

  return (
    <ul className={`menu-nav`}>
      {user &&
        menu(user.seqId)
          [user.type].filter(filterMenu)
          .map(item => {
            return (
              <Fragment key={item.id}>
                <li
                  id="kt_aside_toggle"
                  onClick={() => {
                    if (item.submenu) {
                      setMenuListStatus(prev => ({ ...prev, [item.id]: !prev[item.id] }));
                    } else {
                      toggle.getOffcanvas().hide();
                      history.push(item.route || '/');
                    }
                  }}
                  className={`menu-item ${getMenuItemActive(item.route)}`}
                  style={item.isSubMenu && { backgroundColor: '#000' }}
                  aria-haspopup="true"
                  role="menuitem"
                >
                  <div
                    className="menu-link"
                    style={{
                      padding: item.isSubMenu ? '9px 0px 9px 40px' : '9px 0px 9px 25px',
                      background: menuBackground(item)
                    }}
                    onMouseEnter={e => handelMounseEnter(e, 'menu', item)}
                    onMouseLeave={e => handelMounseLeave(e, 'menu', item)}
                  >
                    <span
                      className="menu-text font-weight-bold"
                      onMouseEnter={e => handelMounseEnter(e, 'font', item)}
                      onMouseLeave={e => handelMounseLeave(e, 'font', item)}
                      style={{
                        color: location.pathname === item.route ? style.focusColor : style.color
                      }}
                    >
                      {formatMessage({ id: item.id })}
                    </span>
                    {item.route === `booking-management` && waitConfirmBookingAmount !== 0 && !isCustomer && (
                      <NotificationIcon>{waitConfirmBookingAmount}</NotificationIcon>
                    )}
                    {item.route === `booking2-management` && totalWaitingBooking2Count !== 0 && !isCustomer && (
                      <NotificationIcon>{totalWaitingBooking2Count}</NotificationIcon>
                    )}
                  </div>
                </li>
                {menuListStatus[item.id] &&
                  item.submenu &&
                  item.submenu.filter(filterSubMenu).map(sub => {
                    return (
                      <li
                        key={`${sub.id}`}
                        id="kt_aside_toggle"
                        onClick={() => {
                          toggle.getOffcanvas().hide();
                        }}
                        className={`menu-item ${getMenuItemActive(sub.route)}`}
                        aria-haspopup="true"
                        role="menuitem"
                      >
                        <NavLink
                          className="menu-link"
                          to={sub.route || '/'}
                          // style={sub.isSubMenu && { padding: '9px 0px 9px 40px' }}
                          style={{
                            padding: sub.isSubMenu ? '9px 0px 9px 40px' : '9px 0px 9px 25px',
                            background: menuBackground(sub)
                          }}
                          onMouseEnter={e => handelMounseEnter(e, 'submenu', sub)}
                          onMouseLeave={e => handelMounseLeave(e, 'submenu', sub)}
                        >
                          <span
                            className="menu-text font-weight-bold"
                            style={{
                              color: location.pathname === sub.route ? style.subFocusColor : style.subColor
                            }}
                            onMouseEnter={e => handelMounseEnter(e, 'subfont', sub)}
                            onMouseLeave={e => handelMounseLeave(e, 'subfont', sub)}
                          >
                            {formatMessage({ id: sub.id })}
                          </span>
                          {sub.route === `/shop/${user.seqId}/booking-management` &&
                            waitConfirmBookingAmount !== 0 &&
                            !isCustomer && <NotificationIcon>{waitConfirmBookingAmount}</NotificationIcon>}
                          {sub.route === `/shop/${user.seqId}/admin/orderManagement` &&
                            waitConfirmOrderAmount !== 0 &&
                            !isCustomer && <NotificationIcon>{waitConfirmOrderAmount}</NotificationIcon>}
                          {sub.route === `/shop/${user.seqId}/booking2-management` &&
                            totalWaitingBooking2Count !== 0 &&
                            !isCustomer && <NotificationIcon>{totalWaitingBooking2Count}</NotificationIcon>}
                        </NavLink>
                      </li>
                    );
                  })}
              </Fragment>
            );
          })}
    </ul>
  );
}

const NotificationIcon = styled('div')(() => ({
  backgroundColor: 'red',
  padding: 4,
  width: 20,
  height: 20,
  borderRadius: 50,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  color: 'white',
  margin: '4px 10px 0 0'
}));

const getRoleMenu = customRole => {
  let menu = [];
  let submenu = [];

  menu.push('MENU_ADMIN_MANAGE');
  Object.entries(customRole).forEach(([key, val]) => {
    if (key === 'dashboard' && !val) menu.push('menu.dashboard');
    if (key === 'specialpackage' && !val) menu.push('SHOP.PACKAGE.CREATE');
    // if (key === 'adminManage' && !val) setHiddenMenuItems(prev => [...prev, 'MENU_ADMIN_MANAGE']);
    if (key === 'reward' && !val) menu.push('MENU_CREATE_PROMOTION');
    if (key === 'coupon' && !val) menu.push('MENU_CREATE_COUPONS');
    if (key === 'qrStaff' && !val) menu.push('menu.sales.management');
    if (key === 'qrPoint' && !val) menu.push('MENU_CREATE_POINT_QRCODE');
    if (key === 'addpointCustomer' && !val) menu.push('menu.add.point.by.customer');
    if (key === 'addpoint' && !val) menu.push('menu.add.point');
    if (key === 'pointHistory' && !val) menu.push('MENU_REDEEM_HISTORY');
    if (key === 'report' && !val) menu.push('MENU_REPORT');
    if (key === 'members' && !val?.invite && !val?.group && !val?.user) menu.push('MENU_MEMBER');
    if (key === 'advertisement' && !val?.advertisement && !val?.broadcast) menu.push('menu.advertisement');
    if (key === 'dashboard2' && !val) menu.push('menu.dashboard.new');

    if (
      key === 'shopManage' &&
      !val?.category &&
      !val?.product &&
      !val?.inventory &&
      !val?.payment &&
      !val?.shipment &&
      !val?.VATservice &&
      !val?.order
    )
      menu.push('menu.shop.management');
    if (key === 'booking' && !val?.manage && !val?.setting && !val?.invite) menu.push('menu.booking.management');
    if (key === 'booking2' && !val?.manage && !val?.setting && !val?.invite) menu.push('menu.booking2.management');
    if (
      key === 'setting' &&
      !val?.shop &&
      !val?.conversion &&
      !val?.theme &&
      !val?.shortcut &&
      !val?.line &&
      !val?.contact
    )
      menu.push('MENU_SETTING');

    if (key === 'contactManagement' && !val.contactAssignable) menu.push('contact.management');

    if (typeof val === 'object')
      Object.entries(val).forEach(([subkey, val]) => {
        if (key === 'members' && subkey === 'invite' && !val) submenu.push('MENU_INVITE_FRIEND');
        if (key === 'members' && subkey === 'group' && !val) submenu.push('MENU_MANAGE_MEMBER_GROUP');
        if (key === 'members' && subkey === 'user' && !val) submenu.push('MENU_USER_MANAGE');
        if (key === 'contactManagement' && subkey === 'assignContact' && !val) {
          submenu.push('contact.management');
        }
        if (key === 'advertisement' && subkey === 'advertisement' && !val) submenu.push('menu.advertisement.manage');
        if (key === 'advertisement' && subkey === 'broadcast' && !val) submenu.push('menu.broadcast.manage');
        if (key === 'shopManage' && subkey === 'category' && !val) submenu.push('menu.category');
        if (key === 'shopManage' && subkey === 'product' && !val) submenu.push('menu.product.list');
        if (key === 'shopManage' && subkey === 'inventory' && !val) submenu.push('menu.inventory');
        if (key === 'shopManage' && subkey === 'payment' && !val) submenu.push('menu.payment.management');
        if (key === 'shopManage' && subkey === 'shipment' && !val) submenu.push('menu.shipment.management');
        if (key === 'shopManage' && subkey === 'VATservice' && !val) submenu.push('menu.shopping.cart.setting');
        if (key === 'shopManage' && subkey === 'order' && !val) submenu.push('menu.admin.order.history');
        if (key === 'booking' && subkey === 'manage' && !val) submenu.push('menu.booking.management');
        if (key === 'booking' && subkey === 'invite' && !val) submenu.push('menu.invite.customer.booking');
        if (key === 'booking' && subkey === 'setting' && !val) submenu.push('menu.booking.setting');
        if (key === 'booking2' && subkey === 'manage' && !val) submenu.push('menu.booking2.management');
        if (key === 'booking2' && subkey === 'invite' && !val) submenu.push('menu.invite2.customer.booking');
        if (key === 'booking2' && subkey === 'setting' && !val) submenu.push('menu.booking2.setting');
        if (key === 'setting' && subkey === 'shop' && !val) submenu.push('MENU_SHOP_SETTING');
        if (key === 'setting' && subkey === 'conversion' && !val) submenu.push('menu.conversion.rate');
        if (key === 'setting' && subkey === 'theme' && !val) submenu.push('menu.theme.setting');
        if (key === 'setting' && subkey === 'shortcut' && !val) submenu.push('menu.shortcut.setting');
        if (key === 'setting' && subkey === 'line' && !val) submenu.push('menu.lineOA.setting');
        if (key === 'setting' && subkey === 'contact' && !val) submenu.push('MENU.CONTACT_US');
      });
  });
  return { menu, submenu };
};
