import * as React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useHistory } from 'react-router-dom';
import { useCustomTheme } from '../../../../app/hooks/useCustomTheme';

export default function ProgressBar({ groups, user, intl, isHomePage, shop }) {
  const history = useHistory();
  const groupWithPoint = groups.filter(group => group.minimumPoint > 0 && group.isMemberLevel) || [];
  const sortedGroups = groupWithPoint.sort((a, b) => a.minimumPoint - b.minimumPoint) || [];
  const userGroup = (user.memberGroups || []).filter(group => group.minimumPoint > 0);
  const groupIndex = sortedGroups.findIndex(group => group.name === userGroup[0]?.name);
  const nextGroup = sortedGroups[groupIndex + 1] || userGroup[0];
  const progressPoint = (user.accumulatedPoint / nextGroup?.minimumPoint) * 100;

  const customTheme = useCustomTheme();
  const style = customTheme?.mainheader;
  return (
    <>
      {groupWithPoint.length > 0 && (
        <div className={isHomePage ? 'my-3' : 'my-6'}>
          <div
            className={`d-flex justify-content-center align-items-center ${isHomePage ? 'my-1' : 'my-2  flex-column'}`}
          >
            <div
              style={{
                fontWeight: 'bold',
                paddingRight: isHomePage ? '4px' : '',
                fontSize: isHomePage ? '10px' : '14px'
              }}
            >
              {intl.formatMessage({ id: 'member.card.upgrade' })}
            </div>
            <div style={{ fontWeight: 'semi-bold', fontSize: isHomePage ? '11px' : '14px' }}>{nextGroup?.name}</div>
          </div>

          {<BorderLinearProgress variant="determinate" value={progressPoint > 100 ? 100 : progressPoint} />}
          <div className="mt-0 d-flex justify-content-center">
            <div
              className={`'d-flex justify-content-center align-items-center ${isHomePage ? 'flex-column my-0' : ''}`}
            >
              <span style={{ fontWeight: 'bold', padding: '8px', fontSize: isHomePage ? '11px' : '14px' }}>
                {intl.formatMessage({ id: 'member.card.level.points' })}
              </span>

              <div className="d-flex justify-content-center align-items-center">
                <div className="pr-3">
                  <span style={{ fontWeight: 'bold', fontSize: isHomePage ? '11px' : '14px' }}>
                    {user.accumulatedPoint}{' '}
                  </span>
                  {' / '}
                  <span style={{ fontWeight: 'bold', fontSize: isHomePage ? '11px' : '14px' }}>
                    {nextGroup?.minimumPoint}
                  </span>
                </div>
                {isHomePage && (
                  <div onClick={() => history.push(`/shop/${shop.seqId}/tier`)}>
                    &nbsp;
                    <OpenInNewIcon style={{ color: style.pointColor }} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));
