import { actionTypes } from '../app/modules/Auth/_redux/authRedux';
import { BACKEND_URL } from '../config';

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken && config.url.includes(BACKEND_URL)) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error && error.response) {
        const errorResponse = error.response;
        if (errorResponse.status === 401) {
          store.dispatch({ type: actionTypes.Timeout });
          if (errorResponse.data.message !== 'You are not logged in') {
            // TODO: hide token expire alert
            // store.dispatch({ type: actionTypes.SetErrorMessage, payload: { error: errorResponse.data.message } });
          }
        } else if (errorResponse.status === 403) {
          store.dispatch({ type: actionTypes.Timeout });
          //TODO: handle specific cases for 403
        }
      }
      return Promise.reject(error);
    }
  );
}
