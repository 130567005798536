import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import objectPath from 'object-path';
import { useLocation } from 'react-router-dom';
// LayoutContext
import { useHtmlClassService } from '../_core/MetronicLayout';
// Import Layout components
import { Header } from './header/Header';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { Aside } from './aside/Aside';
import { Footer } from './footer/Footer';
import { LayoutInit } from './LayoutInit';
import QuickUser from './extras/offcanvas/QuickUser';
import { ScrollTop } from './extras/ScrollTop';
import svg1 from '../../../assets/Rectangle 44.svg';
import svg2 from '../../../assets/Rectangle 45.svg';
import useWindowSize from '../../../app/pages/dashboard/chart/WindowSize';
import { useRouteMatch } from 'react-router-dom';
import { useShouldSelectBranch } from '../../../app/hooks/useShouldSelectBranch';
import { useCustomTheme } from '../../../app/hooks/useCustomTheme';
import '../../../app/pages/shop-setting/styles.css';
import { useLayoutVisibility } from '../../../app/hooks/layoutHooks';

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const isHomePage = location.pathname.includes('/home');
  const isOrderHistoryPage = location.pathname.includes('/order-history');
  const isorderManagementPage = location.pathname.includes('/orderManagement');

  const { user, shopSetting } = useSelector(state => state.auth);

  const isSelectingLoginBranch = useShouldSelectBranch();

  const { hideLayout: layoutIsHidden } = useLayoutVisibility();

  const isBookingDetailPage = useRouteMatch('/shop/:shopId/booking/:bookingId/detail');
  const isMGMPage = useRouteMatch('/shop/:shopId/mgm/detail');

  const hideAsideNotLogin = !user && (isBookingDetailPage || isMGMPage);
  const hideAside = hideAsideNotLogin || isSelectingLoginBranch;

  const size = useWindowSize();

  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended')
    };
  }, [uiService]);

  // const previewTheme = JSON.parse(window.sessionStorage.getItem('THEME_PREVIEW'));
  // const isPreviewTheme = previewTheme?.background ? true : false;
  // const previewImage = JSON.parse(window.sessionStorage.getItem('IMG_PREVIEW'));
  // const isPreviewImagee = previewImage?.img ? true : false;
  // const isPreview = isPreviewImagee || isPreviewTheme ? true : false;

  // const initStyle = {
  //   // ...imageStyle,
  //   // backgroundImage: `url(${bgImage})`+
  //   background: '#5e73b7'
  // };

  // const [background, setBackground] = useState(initStyle);
  // const [showSVG, setShowSVG] = useState(true);

  // useEffect(() => {
  //   const backgroundImage = shopSetting?.backgroundImage;
  //   const backgroundColor = shopSetting?.backgroundColor?.background;
  //   const isShopTheme = backgroundColor ? true : false;

  //   if (isPreview) {
  //     setShowSVG(false);
  //     if (previewImage?.img && !isPreviewTheme) {
  //       const image = new Image();
  //       image.src = URL.createObjectURL(base64ToImage(previewImage?.img));
  //       setBackground({
  //         backgroundImage: `url(${image.src})`,
  //         ...imageStyle
  //       });
  //     } else if (isPreviewTheme && !previewImage?.img) {
  //       setBackground({
  //         background: previewTheme?.background
  //       });
  //     }
  //   } else if (!isPreview && (backgroundImage || backgroundColor)) {
  //     setShowSVG(false);
  //     if (backgroundImage && backgroundImage.length > 0 && !isShopTheme) {
  //       setBackground({
  //         backgroundImage: `url(${backgroundImage[0]})`,
  //         ...imageStyle
  //       });
  //     } else if (isShopTheme) {
  //       setBackground({
  //         background: backgroundColor
  //       });
  //     }
  //   } else {
  //     setShowSVG(true);
  //     setBackground(initStyle);
  //   }
  // }, [shopSetting]);

  // function base64ToImage(base64String) {
  //   if (!base64String) return null;
  //   const base64 = base64String.split('data:image/png;base64,')[1];
  //   const binary = atob(base64);
  //   const array = [];
  //   for (let i = 0; i < binary.length; i++) {
  //     array.push(binary.charCodeAt(i));
  //   }
  //   const blob = new Blob([new Uint8Array(array)], { type: 'image/png' });
  //   return URL.createObjectURL(blob);
  // }

  const customTheme = useCustomTheme();
  const style = customTheme?.main;

  function getPreviewImg(base64String) {
    if (!base64String) return null;
    const base64 = base64String.split('data:image/png;base64,')[1];
    const binary = atob(base64);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(array)], { type: 'image/png' });
    return `url(${URL.createObjectURL(blob)})`;
  }

  const paddingTop = {
    paddingTop: layoutIsHidden ? 0 : undefined
  };

  const paddingLeft = hideAside
    ? {
        paddingLeft: 0
      }
    : {};

  const bg = style.bg ? `url(${style.bg})` : null;

  const wrapperStyle = {
    background: getPreviewImg(style.previewBg) || bg || style.bgColor,
    ...paddingTop,
    ...paddingLeft
  };

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      {/*begin::Main*/}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && !hideAside && <Aside />}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper background"
            id="kt_wrapper"
            style={{
              ...wrapperStyle
            }}
          >
            <Header />
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {/* {layoutProps.subheaderDisplay && <SubHeader/>} */}
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid flex-column">
                  {/*begin::Container*/}
                  {/* <div className={layoutProps.contentContainerClasses} style={{ zIndex: 2 }}> */}
                  <div className={layoutIsHidden ? '' : layoutProps.contentContainerClasses} style={{ zIndex: 2 }}>
                    {children}
                  </div>
                  {/*end::Container*/}
                  {!isHomePage && style.bgColor ? (
                    <>
                      <img
                        src={svg1}
                        style={{ position: 'fixed', bottom: '-60px', width: size.width < 550 ? 'auto' : '100%' }}
                      />
                      <img
                        src={svg2}
                        style={{ position: 'fixed', bottom: 0, width: size.width < 550 ? 'auto' : '100%' }}
                      />
                    </>
                  ) : null}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            <Footer />
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      <QuickUser />
      {/* <QuickPanel /> */}
      <ScrollTop />
      {/* <StickyToolbar /> */}
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
